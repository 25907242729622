import { API } from 'aws-amplify'
import useSWR from 'swr'

const resourceapi = 'resources'

const getSearchParams = asset => ({
  document: asset.handout,
  documentName: asset.title,
  documentDescription: asset.description
})

export function usePdfs (product, asset) {
  const retrieveDocument = async () => {
    try {
      if(product && asset){
        const response = await API.get(resourceapi, `/resources/get-document/${product.id}`, {
          queryStringParameters: getSearchParams(asset)
        })
        return {
          doc: `data:application/pdf;base64,${response.body}`,
          docUrl: response.url
        }
      } else if (product) {
        const response = await API.get(resourceapi, `/resources/getpdf/${product.id}`)
        return {
          doc: `data:application/pdf;base64,${response.body}`,
          docUrl: response.url
        }
      }
    } catch (e) {
      console.log('failed')
      console.log(e)
    }

  }
  const cacheString = `${resourceapi}/resources/getpdf/${product ? product.id : 'unknown'}/${asset ? new URLSearchParams(getSearchParams(asset)).toString() : 'no-search-params'}/`
  const { data } = useSWR(cacheString, retrieveDocument, {refreshInterval: 59000})
  return data
    ? { doc: data.doc, docUrl: data.docUrl, loading: false }
    : { loading: true }
}
