import React from 'react'
import { AmplifyTheme, withAuthenticator } from 'aws-amplify-react'
import { I18n } from 'aws-amplify'
import { isMobile } from 'react-device-detect'
import { Router } from '@reach/router'
import MyProfile from '../../components/MyProfile'
import MyProfileLanding from '../../components/MyProfile/MyProfileLanding'
import Layout from '../../components/layout'
import Lesson from '../../components/MyProfile/Lesson'
import Content from '../../components/MyProfile/content'
import { contentTypes } from '../../components/MyProfile/constants'

const authScreenLabels = {
  en: {
    'Sign in to your account': 'Please log in',
    'Enter your username': 'Enter your email',
    'Username': 'Email',
    'Enter your code': 'Enter verification code sent to your email',
    'Confirmation Code': 'Confirmation code in email'
  }
}
I18n.setLanguage('en')
I18n.putVocabularies(authScreenLabels)

const signUpConfig = {
  hiddenDefaults: [
    'phone_number',
    'email'
  ],
}

const MyTheme = {
  ...AmplifyTheme,
  sectionHeader: { ...AmplifyTheme.sectionHeader, backgroundColor: '#140AD8', borderColor: 'red', color: 'white' },
  container: {
    ...AmplifyTheme.container,
    backgroundColor: '#f9fafb',
    padding: '20px',
    marginTop: '100px',
    marginLeft: isMobile ? '-25px' : undefined
  },
  formSection: { ...AmplifyTheme.formSection, maxWidth: '350px' },
  button: {
    backgroundColor: '#140AD8',
    borderColor: 'red',
    color: 'white',
    padding: '5px 20px',
    marginRight: '20px'
  },
  signInButtonIcon: { display: 'none' }
}

const app = () =>
  <div className="App">
    <Router basepath="/app">
      <MyProfileLanding path="/my-profile" />
      <MyProfile path="/my-profile/:slug/*">
        <Lesson path="/" >
          <Content path={`/${contentTypes.HANDOUT}/:lesson`} />
          <Content path={`/${contentTypes.VIDEO}/:lesson`} />
          <Content path={`/${contentTypes.QS}`} />
          <Content path={`/${contentTypes.BRIEF}`} />
        </Lesson>
      </MyProfile>
    </Router>
  </div>
const AuthApp = withAuthenticator(app, false, [], null, MyTheme, signUpConfig)

const App = () => <Layout><AuthApp /></Layout>
export default App
