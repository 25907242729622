import React, { useContext } from 'react'
import QuestionsComments from './QuestionsComments'
import Handout from './Handout'
import VideoSection from './VideoSection'
import { MyProfileContext } from '../MyProfileContext'
import { contentTypes, useParameters } from '../constants'
import { useVideo } from '../../../hooks/useVideoItems'

const Content = () => {
  const {
    classInfo,
    classProduct,
    briefProduct,
    bundle,
  } = useContext(MyProfileContext).productValues
  const { slug, type, lesson } = useParameters()
  const { video, poster } = useVideo(slug, lesson)
  switch (type) {
    case contentTypes.BRIEF:
    case undefined:
      return classInfo ? <Handout product={briefProduct} title={`${classInfo.title} - Client Brief`} /> : null
    case contentTypes.QS:
      return <QuestionsComments />
    case contentTypes.VIDEO:

      return bundle
        ? <VideoSection product={{ id: bundle.identifier }}
                        bundle={bundle}
                        video={video}
                        poster={poster} />
        : <VideoSection product={classProduct}
                        video={video}
                        poster={poster} />
    case contentTypes.HANDOUT:
      return <Handout asset={video}
                      product={bundle ? { id: video.product_id } : classProduct}
                      title={`${bundle ? video.title : classInfo.title} - Handout`} />
    default:
      throw new Error('Unable to find correct type to display')
  }
}

export default Content
