import React from 'react'
import Vid from './Vid'
import { API } from 'aws-amplify'
import useSWR from 'swr'
import Spinner from '../../Common/Spinner'

const resourceapi = 'resources'

const VideoSection = ({ product, bundle, video, poster }) => {
  const path = bundle ? 'resources/watchbundlevideo' : 'resources/watchvideo'
  const constructVideoJsOptions = async () => {
    if (product && video) {
      const response = await API.get(resourceapi, `/${path}/${product.id}/${video.id}`)
      const videoUrl = response.urls[0]
      let tracks = []
      if (video.subtitles) {
        tracks = tracks.concat(video.subtitles.map(subtitle => ({
          src: `${videoUrl.substring(0, videoUrl.lastIndexOf('/'))}/${subtitle.filename}.vtt`,
          kind: 'captions',
          srclang: subtitle.filename,
          label: subtitle.display
        })))
      }

      const videoJsOptions = {
        video: {
          autoplay: false,
          controls: true,
          src: videoUrl,
          type: 'application/x-mpegURL',
          poster,
          tracks,
        },
        urls: response.urls,
        name: product.name,
        description: product.description
      }
      return { videoJsOptions, manifest: response.manifest }
    }
  }
  const { data } = useSWR(`${resourceapi}/${path}/${product && product.id}/${video && video.id}`, constructVideoJsOptions)
  const { title, description } = data ? data.manifest : { title: '', description: '' }
  return <div
    className="w-full inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          {title}
        </h3>
        <div className="mt-2">
          <div>
            <p className="text-sm leading-5 text-gray-500">
              {description}
            </p>
          </div>
          {data
            ? <div><Vid videoJsOptions={data.videoJsOptions} /></div>
            : <Spinner />
          }
        </div>
      </div>
    </div>
  </div>
}

export default VideoSection
