import React from 'react'
import { usePdfs } from '../../../hooks/usePdfs'
import Spinner from '../../Common/Spinner'
import { sadText } from '../../Common/texts'

const DisplayPdf = ({ docUrl, doc }) => doc
     ? <div>
        <p>
            If the document is not visible below you can<a href={docUrl} className="text-indigo-500"> download a PDF
            from here</a>
        </p>
        <object aria-label="PDF document preview" className="min-h-screen" data={doc} type="application/pdf"
                width="100%" height="100%" />
    </div>
    : <div>
        <p>
            {sadText}
        </p>
    </div>

const Handout = ({ product, asset }) => {
    const { doc, docUrl, loading } = usePdfs(product, asset);
    return product ?
        <div className="min-h-screen">
            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                {asset && asset.title}
            </h4>
            <p className="my-3 text-lg leading-7 text-gray-500">
                {asset && asset.description}
            </p>

            {loading
                ? <Spinner text="Creating document preview" />
                : <DisplayPdf docUrl={docUrl} doc={doc} />
            }
        </div>
        : null;
}

export default Handout
