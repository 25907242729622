import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import useSWR, { mutate } from 'swr'
import { API } from 'aws-amplify'
import * as timeago from 'timeago.js'

import { Editor } from '@tinymce/tinymce-react'
import ReactHtmlParser from 'react-html-parser'
import Spinner from '../../Common/Spinner'
import me from '../../../static/me-avatar.png'
import { useParameters } from '../constants'

const commsApi = 'comms'

const FeedbackModal = ({ setOpen }) => {
  return <div className="fixed z-10 inset-0 overflow-y-auto">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              Message sent successfully
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                You can see messages sent by you and messages that have been answered already.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button type="button"
                  onClick={setOpen}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-700 text-base font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
            OK!
          </button>
        </div>
      </div>
    </div>
  </div>
}

const Avatar = ({ user }) => {
  const username = user || 'Q'
  return <div className="flex-shrink-0">
    <img className="h-10 w-10 rounded-full"
         src={`https://eu.ui-avatars.com/api/?name=${encodeURIComponent(username)}&rounded=true&size=128&background=random`}
         alt="" />
  </div>
}

const Comment = ({ comment }) => <li className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
  <div className="sm:flex sm:justify-between sm:items-baseline">

    <h3 className="text-base font-medium">
      <div className="flex flex-row space-x-6">
        <Avatar user={comment.name} />
        <span className="text-gray-600 mt-2">{comment.name} {' '}wrote</span>
        {comment.email === 'self'
          ? <span className="text-gray-400 text-xs mt-3">(Only visible to you until response is received)</span> : null}
      </div>
    </h3>
    <p className="mt-2 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3">
      <time dateTime="2021-01-28T19:24">{timeago.format(comment.timestamp)}</time>
    </p>
  </div>
  <div className="mt-4 space-y-6 text-sm text-gray-800">
    {ReactHtmlParser(comment.comment)}
  </div>
  {comment.response && comment.response !== ''
    ? <div className="mt-8 text-sm space-x-6 border-t px-8 py-6">
      <div className="flex flex-row space-x-6">
        <div className="flex-shrink-0">
          <img className="h-10 w-10 rounded-full"
               src={me}
               alt="" />
        </div>
        <h2 className="text-md font-bold mt-3 text-gray-700">Response from Sandy</h2>
      </div>
      <div className="mt-4 text-sm text-gray-700">
        <p>{ReactHtmlParser(comment.response)}</p>
      </div>
    </div> : ''}
</li>

const QuestionsComments = () => {
  const { slug } = useParameters()
  const [comment, setComment] = useState('')
  const [messageFeedback, setFeedback] = useState(false)
  const retrieveComments = async () => {
    return await API.get(commsApi, `/comms/comments/${slug}-comments`)
  }
  const {
    data: comments,
    error
  } = useSWR(() => `${commsApi}/comms/comments/${slug}-comments`, retrieveComments)

  const { register, handleSubmit, reset } = useForm()
  const onSubmit = form => {
    API.post(commsApi, `/comms/comments/${slug}-comments`, {
      body: { ...form, published: false, comment, timestamp: new Date().toISOString() }
    })
    setComment('')
    mutate(`${commsApi}/comms/comments/${slug}-comments`)
    setFeedback(true)
    reset()
    setTimeout(() => {
      setFeedback(false)
    }, 5000)
  }

  const handleEditorChange = (content) => {
    setComment(content)
  }
  const loading = !comments && !error
  return <section aria-labelledby="notes-title">
    <div className="bg-gray-50 shadow sm:rounded-lg sm:overflow-hidden">
      <div className="divide-y divide-gray-200">
        <div className="px-4 py-5 sm:px-6">
          <h2 id="notes-title" className="text-lg font-medium text-gray-900">Do you have a question?</h2>
        </div>

        <div className="px-4 py-6 sm:px-6">
          <div className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900">
            <svg className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd" />
            </svg>
            <span> You can write your question to me here. I post answers to questions every Monday. <br />
          </span>
          </div>
          {loading
            ? <Spinner />
            : comments && comments.length > 0
              ? <ul className="py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8">
                {comments && comments.map(it =>
                  <Comment key={it.comment} comment={it} />
                )}
              </ul>
              : <div className="mx-auto mt-5 text-gray-700">No published questions here yet. Maybe you want to ask
                something?</div>
          }


        </div>
      </div>
      {loading ? null :
        <div className="bg-gray-50 px-4 py-5 sm:px-6 sm:space-y-4 lg:px-8">
          <div className="flex space-x-3">
            <div className="min-w-0 flex-1">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label htmlFor="user" className="sr-only">Display name</label>
                  <input
                    ref={register({ required: true })}
                    id="name" name="name"
                    className="shadow-sm block w-2/4 focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                    type="text" placeholder="Your name" />
                </div>
                <div className="mt-4">
                  <label htmlFor="comment" className="sr-only">Ask a question</label>

                  <Editor
                    textareaName="comment"
                    value={comment}
                    initialValue=""
                    className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                    apiKey="8la042sb0kwnbrsnjl63vq6av0mw2pxdwz0vfith1jey9e9r"
                    init={{
                      height: 400,
                      menubar: false,
                      branding: false,
                      plugins: [
                        'advlist autolink lists link image',
                        'print preview',
                        'searchreplace code',
                        'insertdatetime media table paste'
                      ],
                      toolbar:
                        'undo redo | bold italic | bullist numlist outdent indent'
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                <div className="mt-3 flex items-center space-x-4">

                  <button type="submit"
                          className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Send
                  </button>
                </div>
                {messageFeedback ? <div className="text-blue-700">
                    Message sent successfully!
                  </div>
                  : null}

              </form>
            </div>
          </div>
        </div>
      }
    </div>
    {messageFeedback ? <FeedbackModal setOpen={() => setFeedback(false)} /> : null}
  </section>
}

export default QuestionsComments
