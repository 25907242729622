import React from 'react'
import 'video.js/dist/video-js.css'
import { useMatch } from '@reach/router'
import MyProfileContextProvider from './MyProfileContext'
import { classMatchPath } from './constants'

const MyProfile = ({children}) => {
  const { slug } = useMatch(classMatchPath)
  return <MyProfileContextProvider slug={slug}>
    {children}
  </MyProfileContextProvider>
}

export default MyProfile
