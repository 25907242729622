import { sadText } from '../Common/texts'
import React, { useEffect, useRef } from 'react'
import { API } from 'aws-amplify'
import useSWR from 'swr'
import Spinner from '../Common/Spinner'

const resourceapi = 'resources'

export function useWaxConversionPdf () {
  const retrieveDocument = async () => {
    try {
      // Hardcoded document name in the backend. A dummy UUID passed in
      const response = await API.get(resourceapi, `/resources/additional-documents/0cb49646-3ab4-4f3f-9bba-b17b254c8bdf`, {})
      return {
        doc: `data:application/pdf;base64,${response.body}`,
        docUrl: response.url
      }
    } catch (e) {
      console.log('failed')
      console.log(e)
    }

  }
  const { data } = useSWR(`/resources/additional-documents/0cb49646-3ab4-4f3f-9bba-b17b254c8bdf`, retrieveDocument, { refreshInterval: 59000 })
  return data
    ? { doc: data.doc, docUrl: data.docUrl, loading: false }
    : { loading: true }
}

const DisplayPdf = ({ docUrl, doc }) => doc
  ? <div>
    <p>
      If the document is not visible below you can<a href={docUrl} className="text-indigo-500"> download a PDF
      from here</a>
    </p>
    <object aria-label="PDF document preview" className="min-h-screen" data={doc} type="application/pdf"
            width="100%" height="100%" />
  </div>
  : <div>
    <p>
      {sadText}
    </p>
  </div>

export const DocumentModal = ({ close }) => {
  const wrapperRef = useRef(null)
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
      document.removeEventListener("keydown", escFunction, false);
    }
  }, [])
  const escFunction = (event) => {
    if(event.keyCode === 27) {
      close()
    }
  }
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      close()
    }
  }

  const { doc, docUrl, loading } = useWaxConversionPdf()
  return <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        ref={wrapperRef}
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
        <div className="absolute top-2 right-2">
          <button type="button"
                  onClick={close}
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Wax to Metal conversion
            </h3>
            <div className="mt-2">
              {loading
                ? <Spinner text="Creating document preview" />
                : <DisplayPdf docUrl={docUrl} doc={doc} />
              }
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
          <button type="button"
                  onClick={close}
                  className="mx-auto w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 sm:text-sm">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
}

